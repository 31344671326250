import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogList = ({data, location, pageContext}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMarkdownRemark.nodes
    const author = data.site.siteMetadata?.author?.name
    const { previousPage, nextPage, currentPage } = pageContext

    return (
      <Layout location={location} title={siteTitle} author={author}>
        <SEO title={`Blogs page ${currentPage}`} />
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <article
              key={post.fields.slug}
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2>
                  <Link to={post.fields.slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
                <small>{post.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </section>
            </article>
          )
        })}
        <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previousPage && (
                <Link to={`/blog/page_${previousPage}`} rel="prev">
                  ← Page {previousPage}
                </Link>
              )}
            </li>
            <li>
              {nextPage && (nextPage === 1 ? (
                <Link to={`/`} rel="next">
                  Page {nextPage} →
                </Link>
              ) : (
                <Link to={`/blog/page_${nextPage}`} rel="next">
                  Page {nextPage} →
                </Link>
              ))}
            </li>
          </ul>
        </nav>
      </Layout>
    )
}

export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        author {
          name
        }
        title
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "blog"} }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
    }
  }
`
